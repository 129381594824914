import type { IReadMerchantDto } from '@goparrot/store-v2-sdk';
import React from 'react';

import { Logo } from './styles';

interface Props {
  headerLogo?: {
    url: string;
    openInNewTab: boolean;
    image: string;
  };
  merchantStore: IReadMerchantDto;
}

const HeaderLogo: React.FC<Props> = ({ headerLogo, merchantStore }) =>
  headerLogo && headerLogo.url ? (
    <Logo href={headerLogo.url} rel="noopener noreferrer" target={headerLogo.openInNewTab ? '_blank' : '_parent'}>
      <img src={merchantStore ? headerLogo.image : ''} alt={merchantStore.title} />
    </Logo>
  ) : (
    <img src={merchantStore && headerLogo ? headerLogo.image : ''} alt={merchantStore.title} />
  );
export default HeaderLogo;
