import { useEffect } from 'react';
import get from 'lodash/get';
import qs from 'qs';
import { useQueryLocationsStores, useQueryPlacePickerStores } from '../api';
import { useLocationsDispatch } from '../contexts/LocationStoresProvider';

export const useGetFilteredStores = (tags = '') => {
  const stores = useQueryLocationsStores(window.merchantId);
  const placePickerStores = useQueryPlacePickerStores(window.merchantId);
  const handleDispatch = useLocationsDispatch();

  useEffect(() => {
    if (stores.isSuccess && placePickerStores.isSuccess) {
      let filteredStores = stores.data.filter((store) => {
        const placePickerStore = placePickerStores.data && placePickerStores.data.find((placePickerStore) => placePickerStore.storeId === store.storeId);
        return get(placePickerStore, 'enabled', true);
      });
      if (tags) {
        const getQueryString = qs.parse(tags, { ignoreQueryPrefix: true });

        const storesFilter = Array.isArray(getQueryString.tags) ? (getQueryString.tags as string[]).filter(Boolean) : null;
        if (storesFilter && storesFilter.length) {
          filteredStores = filteredStores.filter((store) => {
            const placePickerStore = placePickerStores && placePickerStores.data.find((placePickerStore) => placePickerStore.storeId === store.storeId);
            if (placePickerStore && placePickerStore.tags.length) {
              const filteredTags = placePickerStore.tags.map((tag) => tag.toLowerCase());

              return storesFilter.every((tag) => filteredTags.includes(tag));
            }
            return false;
          });
        }
      }

      handleDispatch({ type: 'setStores', payload: filteredStores ?? [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores.isSuccess, placePickerStores.isSuccess]);

  return { isLoading: stores.isLoading || placePickerStores.isLoading, stores: stores.data };
};
