import React from 'react';
import { useLocationsState } from '../../contexts/LocationStoresProvider';
import type { IPlacePickerTemplate } from '../../themes';
import { Banner, BannerMobile } from './styles';

interface IProps {
  isMobile?: boolean;
  theme: IPlacePickerTemplate;
}
export const StoreBanner: React.FC<IProps> = (props) => {
  const { selectedLocation } = useLocationsState();

  const { isMobile = false, theme } = props;
  const { banner } = theme;

  const isBannerReady = !isMobile && banner && banner.imgUrl && banner.imgUrl.desktop;
  const isMobileBannerReady = isMobile && banner && banner.imgUrl && banner.imgUrl.mobile;

  if (isBannerReady && (!selectedLocation || banner?.alwaysOnDisplay?.desktop)) {
    return <Banner />;
  } else if (isMobileBannerReady && (!selectedLocation || banner?.alwaysOnDisplay?.mobile)) {
    return <BannerMobile />;
  } else {
    return null;
  }
};
