import type { DefaultTheme } from 'styled-components';

const themeColor = '#fcaf17';
const primaryColor = '#000';
const secondaryColor = '#f4bb23';
const textGray = '#5d5d5d';
const regularSize = '14px';
// const largerSize = '18px';
const secondaryFont = '"PT Sans", "system", sans-serif';
export const sortOrderEnum = Object.freeze({ NONE: 'NONE', A2Z: 'A2Z', Z2A: 'Z2A' }); // options: 'NONE' (no sorting, default), 'A2Z' (A-Z / alphabetical), 'Z2A' (Z-A / reverse-alphabetical)
export const titleTextTransformEnum = Object.freeze({ UPPERCASE: 'uppercase', LOWERCASE: 'lowercase', CAPITALIZE: 'capitalize', NONE: 'none' }); // options: 'uppercase' (e.g. "BEST STORE"), 'lowercase' (e.g. "best store"), 'capitalize' (e.g. "Best Store"), 'none' (as typed, default)
export const boxCornerShapeEnum = Object.freeze({ ROUND: 0, SHARP: 1 }); // options: 'round', 'sharp' (default)

interface IFontFamilyStyle {
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  color: string;
}
interface IDeviceProps<T> {
  mobile: T;
  desktop: T;
}
export interface IPlacePickerTemplate extends DefaultTheme {
  bg: {
    header: string;
    main: string;
  };
  color: {
    primary: string;
    secondary: string;
  };
  fontFamily: {
    primary: string;
    secondary: string;
    bold: string;
    title: string;
    searchButton?: string;
  };
  fontSize: {
    primary: string;
    primaryMobile: string;
    title: string;
    titleMobile: string;
    titleSmaller: string;
  };
  fontSources?: {
    name: string;
    src: string;
    format: string;
    weight?: string;
    style?: string;
  }[];
  textTransform: {
    title: string;
    searchButton: string;
  };
  position: {
    sidebar: string;
  };
  header: {
    height: string;
  };
  homeButton: {
    font: string;
    color: string;
    size: string;
    weight: string;
  };
  infoBoxContainer?: IFontFamilyStyle & {
    backgroundColor: string;
    borderColor: string;
  };
  searchAddressInput: {
    hide: boolean;
  };
  map: {
    mapTypeControl: boolean;
    mapTypeControlOptionsHidden: boolean;
    streetViewControlEnabled: boolean;
    styles: {
      featureType: string;
      elementType: string;
      stylers: Record<any, any>[];
    }[];
  };
  mapMarker?: string;
  placePickerTitle: string;
  placePickerSearchTitle: string;
  sidebar: {
    backgroundColor: string;
  };
  receiveMethod: {
    default: string;
    show: boolean;
  };
  storesContainer: {
    backgroundColor: string;
    zipInput: {
      title: {
        color: string;
      };
      subtitle: {
        color: string;
      };
    };
    findButton: {
      hoverColor: string;
      hoverBackgroundColor: string;
    };
    showAllButton: {
      fontSize: string;
      color: string;
    };
  };
  storeList: {
    sortOrder: string;
    emptyListMessage: string;
    emptyDeliveryAddressList: string;
  };
  storeCard: {
    arrowIconColor: string;
    backgroundColor: string;
    borderOnHoverColor: string;
    title: {
      fontFamily?: string;
      fontSize?: string;
      fontWeight?: string;
      color?: string;
      textTransform: string;
    };
    storeAddress?: IFontFamilyStyle & {
      textDecoration: string;
    };
    phoneNumber?: IFontFamilyStyle;
    distanceBetweenStores?: IFontFamilyStyle;
    workingHours?: IFontFamilyStyle;
    dineInHours?: IFontFamilyStyle;
    receiveMethods?: IFontFamilyStyle & {
      visible: boolean;
    };
    orderAhead: {
      visible: boolean;
      fontFamily?: string;
      fontWeight?: string;
      fontSize?: string;
      color?: string;
    };
    orderNow?: IFontFamilyStyle & {
      textTransform: string;
      padding: string;
      backgroundColor: string;
      hoverColor: string;
      hoverBackgroundColor: string;
      boxCornerShape: number;
    };
  };
  storeListSortOrder: string;
  headerLogo?: {
    url: string;
    image: string;
    openInNewTab: boolean;
  };
  logoWidth?: string;

  banner?: {
    enabled?: IDeviceProps<boolean>;
    alwaysOnDisplay?: IDeviceProps<boolean>;
    imgUrl?: IDeviceProps<string>;
    imgPosition?: IDeviceProps<string>;
    backgroundColor?: IDeviceProps<string>;
    imgSize?: IDeviceProps<string>;
  };
  isNewLocationSelectorEnabled?: boolean;
  searchTextPlaceholder?: string;
}

export const defaultTheme = {
  // banner: {
  //   enabled: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   alwaysOnDisplay: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   imgUrl: {
  //     mobile: '',
  //     desktop: '',
  //   },
  //   imgPosition: {
  //     mobile: 'center',
  //     desktop: 'center',
  //   },
  //   imgSize: {
  //      mobile: 'contain',
  //      desktop: 'contain',
  //   }
  //   backgroundColor: {
  //     mobile: 'white',
  //     desktop: 'white',
  //   },
  // },
  bg: {
    header: themeColor,
    main: 'red',
  },
  color: {
    primary: themeColor,
    secondary: secondaryColor,
  },
  fontFamily: {
    primary: 'Brandon Text Medium',
    secondary: secondaryFont,
    bold: 'Brandon Text Black Italic',
    title: 'HoboExtraBold',
    // searchButton: 'Arial',
  },
  fontSize: {
    primary: regularSize,
    primaryMobile: '10px',
    title: '21px',
    titleMobile: regularSize,
    titleSmaller: '20px',
  },
  // fontSources: [
  //     {
  //         name: 'ProximaBold',
  //         src: 'https://res.cloudinary.com/druhu3xb6/raw/upload/v1567014669/Reverie_Roasters/logo_banner/ProximaNova-Bold.ttf',
  //         format: 'truetype',
  //         weight: '400',
  //     },
  //     {
  //         name: 'KnockoutLt',
  //         src: 'https://res.cloudinary.com/druhu3xb6/raw/upload/v1565533461/Wafels_and_Dinges/logos/Knockout-29JuniorLtweight.otf',
  //         format: 'opentype',
  //     },
  //     {
  //         name: 'Font1',
  //         src: 'https://res.cloudinary.com/druhu3xb6/raw/upload/v1579702322/Daylight_Donuts/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2',
  //         format: 'woff2',
  //         weight: '700',
  //     },
  // ],
  textTransform: {
    title: 'uppercase',
    searchButton: 'none',
  },
  position: {
    sidebar: 'left',
  },
  header: {
    height: '80px',
  },
  // headerLogo: {
  //     url: 'https://www.goparrot.ai',
  //     image: 'https://v.fastcdn.co/u/4e50744a/45630910-0-GoParrot-Wordmark-no.png',
  //     openInNewTab: false,
  // },
  // logoWidth: '220px',
  // homeURLText: 'HOME',
  // homeURL: 'https://www.goparrot.ai',
  homeButton: {
    font: secondaryFont,
    color: primaryColor,
    size: regularSize,
    weight: '700',
  },
  // infoBoxContainer: {
  // backgroundColor: '#fff',
  // borderColor: '#691e06',
  // fontFamily: 'Texgyre',
  // fontSize: '14px',
  // fontWeight: '600',
  // color: '#404040',
  // },
  searchAddressInput: {
    hide: false,
  },
  map: {
    mapTypeControl: true,
    mapTypeControlOptionsHidden: false,
    streetViewControlEnabled: true, // enable/disable street view pegman
    styles: [],
  },
  // mapMarker: 'https://res.cloudinary.com/druhu3xb6/image/upload/q_auto:low/v1569834020/logo/89132bf0-7c74-47ac-ba5f-d2cb5a5310c2.png',
  placePickerTitle: "Let's get started",
  placePickerSearchTitle: 'Enter your address to find a GoParrot location near you',
  sidebar: {
    backgroundColor: '',
  },
  receiveMethod: {
    default: 'all',
    show: false,
  },
  storesContainer: {
    backgroundColor: 'transparent',
    zipInput: {
      title: {
        color: primaryColor,
      },
      subtitle: {
        color: textGray,
      },
    },
    findButton: {
      hoverColor: '#fff',
      hoverBackgroundColor: '#777',
    },
    showAllButton: {
      fontSize: regularSize,
      color: primaryColor,
    },
  },
  storeList: {
    sortOrder: sortOrderEnum.NONE,
    emptyListMessage: 'No results have been found',
    emptyDeliveryAddressList: 'Sorry, we have no locations around this address.',
  },
  storeCard: {
    arrowIconColor: '#404040',
    backgroundColor: 'transparent',
    borderOnHoverColor: '#fefefe',
    title: {
      // fontFamily: 'Arial',
      // fontSize: '21px',
      // fontWeight: '600',
      // color: '#000',
      textTransform: titleTextTransformEnum.NONE,
    },
    // storeAddress: {
    // fontFamily: 'Arial',
    // fontSize: '14px',
    // fontWeight: '600',
    // color: '#000',
    // textDecoration: 'underline',
    // },
    // phoneNumber: {
    // fontFamily: 'Arial',
    // fontSize: '12px',
    // fontWeight: '',
    // color: '#999',
    // },
    // distanceBetweenStores: {
    // fontFamily: 'Arial',
    // fontSize: '12px',
    // fontWeight: '600',
    // color: '#a28484',
    // },
    // workingHours: {
    // fontFamily: 'Arial',
    // fontSize: '12px',
    // fontWeight: '100',
    // color: '#999',
    // },
    // dineInHours: {
    // fontFamily: 'Arial',
    // fontSize: '14px',
    // fontWeight: '600',
    // color: '#000',
    // },
    // receiveMethods: {
    // fontFamily: 'Arial',
    // fontSize: '12px',
    // fontWeight: '600',
    // color: '#999',
    // visible: true,
    // },
    orderAhead: {
      // fontFamily: 'Arial',
      // fontSize: '14px',
      // fontWeight: '400',
      // color: 'rgba(0, 0, 0, 0.65)',
      visible: true,
    },
    // orderNow: {
    // fontFamily: 'Texgyre',
    // fontWeight: '600',
    // fontSize: '16px',
    // color: '#fff',
    // textTransform: 'capitalize',
    // padding: '5px',
    // backgroundColor: '#000',
    // hoverColor: '#fff',
    // hoverBackgroundColor: '#afafaf',
    // boxCornerShape: boxCornerShapeEnum.SHARP,
    // },
  },
  storeListSortOrder: sortOrderEnum.NONE,
} as IPlacePickerTemplate;
