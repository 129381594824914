import { Layout } from 'antd';
import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export default styled(Layout)<{ theme: IPlacePickerTemplate }>`
    padding: 0 0 24px;
    background: whitesmoke;
    margin-${({ theme }) => theme.position.sidebar}: 550px;
    @media (max-width: 992px) {
        margin-${({ theme }) => theme.position.sidebar}: 350px;
    }
`;
