import type { IHumanReadablePlacePickerStoresDto } from '@goparrot/webstore-sdk';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { locationSelectorFacade } from '../facades';
import { LOCATION_STORES_QUERIES_ENUMS } from '../enums';

type UseQueryWebstoreKeys = Array<LOCATION_STORES_QUERIES_ENUMS.LOCATION_STORES_LIST>;

export const useQueryLocationsStores = (
  merchantId: string,
  options?: UseQueryOptions<IHumanReadablePlacePickerStoresDto[], unknown, IHumanReadablePlacePickerStoresDto[], UseQueryWebstoreKeys>,
): UseQueryResult<IHumanReadablePlacePickerStoresDto[]> => {
  return useQuery([LOCATION_STORES_QUERIES_ENUMS.LOCATION_STORES_LIST], async () => locationSelectorFacade.getStoresByMerchantId(merchantId), { ...options });
};
