import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export default styled.header<{ theme: IPlacePickerTemplate }>`
  background: ${({ theme }) => theme.bg.header};
  height: ${({ theme }) => theme.header.height};
  min-height: ${({ theme }) => theme.header.height};
  display: flex;
  align-items: center;
  padding: 0 30px;
  border: 0;
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 0%, 0.2);
  z-index: 2;

  @media (max-width: 796px) {
    padding: 0 10px;
  }
`;
