import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export default styled.a<{ theme: IPlacePickerTemplate }>`
  position: absolute;
  font-family: ${({ theme }) => theme.homeButton.font};
  font-weight: ${({ theme }) => theme.homeButton.weight};
  color: ${({ theme }) => theme.homeButton.color};
  font-size: ${({ theme }) => theme.homeButton.size};

  &:hover {
    color: ${({ theme }) => theme.homeButton.color};
  }
`;
