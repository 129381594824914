import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import type { IMerchantTemplate } from '../facades';
import { locationSelectorFacade } from '../facades';

import { LOCATION_STORES_QUERIES_ENUMS } from '../enums';

type UseQueryWebstoreKeys = Array<LOCATION_STORES_QUERIES_ENUMS.LOCATION_STORES_TEMPLATE>;

export const useQueryLocationsStoresTemplate = (
  merchantId: string,
  options?: UseQueryOptions<IMerchantTemplate, unknown, IMerchantTemplate, UseQueryWebstoreKeys>,
): UseQueryResult<IMerchantTemplate> => {
  return useQuery([LOCATION_STORES_QUERIES_ENUMS.LOCATION_STORES_TEMPLATE], async () => locationSelectorFacade.getTemplateByMerchantId(merchantId), {
    ...options,
  });
};
