import type { FullFilledGeoLocation } from '@goparrot/common';
import { useEffect, useState } from 'react';

import { useQueryStoreUrls } from '../api';

type FullFilledGeoLocationWithRaw = FullFilledGeoLocation & { raw?: unknown };
export interface RedirectToStoreProps {
  storeId: string;
  deliveryLocation?: FullFilledGeoLocationWithRaw | false;
}
export const useRedirectToStore = () => {
  const [storeId, setStoreId] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState<FullFilledGeoLocationWithRaw | false>();
  const { refetch } = useQueryStoreUrls(storeId, { enabled: false });

  useEffect(() => {
    if (storeId)
      refetch()
        .then(({ data }) => {
          let redirectUrl = data?.placePickerRedirectToUrl;

          if (deliveryLocation && deliveryLocation?.houseNumber) {
            const location = { ...deliveryLocation };
            delete location.raw;
            redirectUrl += '?address=' + encodeURIComponent(JSON.stringify(location));
          } else {
            redirectUrl += '?pickup=true';
          }
          if (redirectUrl) {
            setStoreId('');
            window.location.href = redirectUrl;
          }
        })
        .catch((err) => console.log(err));
  }, [deliveryLocation, refetch, storeId]);

  return ({ storeId, deliveryLocation = false }: RedirectToStoreProps) => {
    setStoreId(storeId);
    setDeliveryLocation(deliveryLocation);
  };
};
