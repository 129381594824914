import type { IHumanReadablePlacePickerStoresDto, ReadPlacePickerStoreDto } from '@goparrot/webstore-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';

import type { IPlacePickerTemplate } from '../../themes';

export interface IMerchantTemplate {
  merchantId: string;
  template: IPlacePickerTemplate;
}

export interface IDeliverableStores {
  distance?: number;
  storeId: string;
}

export class LocationSelectorFacade {
  async getStoresByMerchantId(merchantId: string): Promise<IHumanReadablePlacePickerStoresDto[]> {
    return axios.get(`${window.WEBSTORE_GATEWAY_URL}/api/v3/merchants/${merchantId}/place-picker-stores-with-stores`);
  }

  async getPlacePickerStores(merchantId: string): Promise<ReadPlacePickerStoreDto[]> {
    return axios.get(`${window.WEBSTORE_GATEWAY_URL}/api/v2/merchants/${merchantId}/place-picker-stores`);
  }

  async getTemplateByMerchantId(merchantId: string): Promise<IMerchantTemplate> {
    return axios.get(`${window.WEBSTORE_GATEWAY_URL}/api/v2/template-by-merchants/${merchantId}`);
  }

  async getWebstoreUrls(storeId: string): Promise<any> {
    return axios.get(`${window.WEBSTORE_GATEWAY_URL}/api/v2/web-stores/${storeId}/urls`);
  }

  async getDeliverableStores(latitude = 0, longitude = 0, distanceUnit = 'mi'): Promise<IDeliverableStores[]> {
    return axios.get(
      `${window.WEBSTORE_GATEWAY_URL}/api/v2/merchants/${window.merchantId}/deliverable-stores?latitude=${latitude}&longitude=${longitude}&distanceUnit=${distanceUnit}`,
    );
  }
}

export const locationSelectorFacade = new LocationSelectorFacade();
