import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export const Logo = styled.h1<{ theme: IPlacePickerTemplate }>`
  margin: 0 auto;
  width: ${({ theme }) => theme.logoWidth || '155px'};

  img {
    width: 100%;
  }
`;

export const Content = styled.div``;

export const Layout = styled.div``;
