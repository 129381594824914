import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useReducer } from 'react';
import type { IHumanReadablePlacePickerStoresDto } from '@goparrot/webstore-sdk';

export interface LocationBounds {
  location?: { lng: number; lat: number } | null;
  viewport?:
    | {
        south: number;
        west: number;
        north: number;
        east: number;
      }
    | {
        southwest: { lng: number; lat: number };
        northeast: { lng: number; lat: number };
      }
    | google.maps.LatLngBounds;
}

export interface MapDetails {
  zoom?: number;
  center?: { lat?: number; lng?: number };
  sortingCenter?: { lat?: number; lng?: number };
}

export interface IPlacePickerStores extends IHumanReadablePlacePickerStoresDto {
  distance?: number | null;
  deliveryRadiusContainsLocation?: boolean;
}

export type DeliveryType = 'all' | 'pickup' | 'delivery';

export type LocationStoresActions =
  | { type: 'update'; payload: Partial<LocationStoresProvider> }
  | { type: 'setShowLiveMap'; payload: boolean }
  | { type: 'setLocation'; payload: LocationBounds | null }
  | { type: 'setMapDetails'; payload: MapDetails | null }
  | { type: 'setSearchBoxValue'; payload: string }
  | { type: 'resetMapDetails'; payload: MapDetails | null }
  | { type: 'resetAllLocations'; payload: boolean }
  | { type: 'setFilterStatus'; payload: DeliveryType }
  | { type: 'setIsDeliveryStrategy'; payload: boolean }
  | { type: 'setStores'; payload: IPlacePickerStores[] }
  | { type: 'setFilteredLocations'; payload: IPlacePickerStores[] }
  | { type: 'setClosestLocation'; payload: IPlacePickerStores | null }
  | { type: 'setVisibleLocations'; payload: IPlacePickerStores[] };

export interface LocationStoresProvider {
  showDynamicMap: boolean;
  mapDetailsFromStore: MapDetails | null;
  searchBoxValue: string;
  selectedLocation: LocationBounds | null;
  filterStatus: DeliveryType;
  resetAllLocations: boolean;
  isDeliveryStrategy: boolean;
  stores: IPlacePickerStores[];
  filteredStoreLocations: IPlacePickerStores[];
  visibleStoreLocations: IPlacePickerStores[];
  closestStoreLocation: IPlacePickerStores | null;
}

export const locationsUseReducerInitialState: LocationStoresProvider = {
  showDynamicMap: false,
  mapDetailsFromStore: null,
  searchBoxValue: '',
  selectedLocation: null,
  filterStatus: 'all',
  resetAllLocations: false,
  isDeliveryStrategy: false,
  stores: [],
  filteredStoreLocations: [],
  visibleStoreLocations: [],
  closestStoreLocation: null,
};

type Dispatch = (action: LocationStoresActions) => void;

const LocationsContext = createContext<LocationStoresProvider>(locationsUseReducerInitialState);
const LocationsDispatchContext = createContext<Dispatch>(() => void 0);

export const locationsReducer = (state: LocationStoresProvider, action: LocationStoresActions) => {
  switch (action?.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'setShowLiveMap':
      return { ...state, showDynamicMap: action.payload };
    case 'setLocation':
      return { ...state, selectedLocation: action.payload };
    case 'setMapDetails':
      return { ...state, mapDetailsFromStore: action.payload };
    case 'setSearchBoxValue':
      return { ...state, searchBoxValue: action.payload };
    case 'resetMapDetails':
      return { ...state, searchBoxValue: '', selectedLocation: null, mapDetailsFromStore: action.payload };
    case 'resetAllLocations':
      return { ...state, resetAllLocations: action.payload };
    case 'setFilterStatus':
      return { ...state, filterStatus: action.payload };
    case 'setIsDeliveryStrategy':
      return { ...state, isDeliveryStrategy: action.payload };
    case 'setStores':
      return {
        ...state,
        stores: action.payload,
        filteredStoreLocations: action.payload,
      };
    case 'setFilteredLocations':
      return {
        ...state,
        filteredStoreLocations: action.payload,
      };
    case 'setClosestLocation':
      return { ...state, closestStoreLocation: action.payload };
    case 'setVisibleLocations':
      return {
        ...state,
        visibleStoreLocations: action.payload,
      };
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      // @ts-ignore
      throw new Error(`Unhandled action type: ${action?.type}`);
    }
  }
};

export const LocationsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(locationsReducer, { ...locationsUseReducerInitialState });

  const handleDispatch = useCallback((action: LocationStoresActions) => {
    dispatch(action);
  }, []);

  return (
    <LocationsContext.Provider value={state}>
      <LocationsDispatchContext.Provider value={handleDispatch}>{children}</LocationsDispatchContext.Provider>
    </LocationsContext.Provider>
  );
};

export const useLocationsState = () => {
  const context = useContext(LocationsContext);
  if (context === undefined) {
    throw new Error('useLocationsState must be used within a LocationsProvider');
  }
  return context;
};

export const useLocationsDispatch = () => {
  const context = useContext(LocationsDispatchContext);
  if (context === undefined) {
    throw new Error('useLocationsDispatch must be used within a LocationsDispatchContext');
  }
  return context;
};
