import { MerchantProvider } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { ReactQueryProvider } from '@webstore-monorepo/shared/contexts/react-query-provider';
import { UserContextProvider } from '@webstore-monorepo/shared/contexts/user-context-provider';
import React from 'react';
import { hot } from 'react-hot-loader';

import { NotificationProvider } from '../../shared/contexts/notification-provider';
import { LocationsProvider } from '../contexts/LocationStoresProvider';
import { MainContainer } from './main-container';

const Root = hot(module)(() => (
  <ReactQueryProvider>
    <UserContextProvider DD_RUM_ENABLED={!!window.DD_RUM_ENABLED} merchantId={window.SSRContext?.merchant?.merchantId} storeId="">
      <NotificationProvider>
        <MerchantProvider initial={window.SSRContext.merchant}>
          <LocationsProvider>
            <MainContainer />
          </LocationsProvider>
        </MerchantProvider>
      </NotificationProvider>
    </UserContextProvider>
  </ReactQueryProvider>
));

export default Root;
