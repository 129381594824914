import type { ReadPlacePickerStoreDto } from '@goparrot/webstore-sdk';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { locationSelectorFacade } from '../facades';
import { LOCATION_STORES_QUERIES_ENUMS } from '../enums';

type UseQueryPlacePickerStore = Array<LOCATION_STORES_QUERIES_ENUMS.PLACE_PICKER_STORES_LIST>;

export const useQueryPlacePickerStores = (
  merchantId: string,
  options?: UseQueryOptions<ReadPlacePickerStoreDto[], unknown, ReadPlacePickerStoreDto[], UseQueryPlacePickerStore>,
): UseQueryResult<ReadPlacePickerStoreDto[]> => {
  return useQuery([LOCATION_STORES_QUERIES_ENUMS.PLACE_PICKER_STORES_LIST], async () => locationSelectorFacade.getPlacePickerStores(merchantId), {
    ...options,
  });
};
