import { Layout } from 'antd';
import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export default styled(Layout.Sider)<{ theme: IPlacePickerTemplate }>`
  background: whitesmoke;
  height: 100vh;
  overflow: auto;
  position: fixed;
  ${({ theme }) => theme.position.sidebar}: 0;
`;
