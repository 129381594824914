import 'antd/es/spin/style/css';

import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  pastDelay?: string | React.ReactNode;
  timedOut?: boolean;
}

export const Spinner: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.error ? 'Error! Please refresh the page' : ''}
      {props.isLoading ? <LoadingOutlined /> : ''}
      {props.pastDelay}
      {props.timedOut ? 'Taking a long time...' : ''}
    </React.Fragment>
  );
};
