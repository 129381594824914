import type { WebStoreUrlsDto } from '@goparrot/webstore-sdk';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { locationSelectorFacade } from '../facades';
import { LOCATION_STORES_QUERIES_ENUMS } from '../enums';

type UseQueryPlacePickerStore = Array<LOCATION_STORES_QUERIES_ENUMS.STORE_URLS>;

export const useQueryStoreUrls = (
  storeId: string,
  options?: UseQueryOptions<WebStoreUrlsDto, unknown, WebStoreUrlsDto, UseQueryPlacePickerStore>,
): UseQueryResult<WebStoreUrlsDto> => {
  return useQuery([LOCATION_STORES_QUERIES_ENUMS.STORE_URLS], async () => locationSelectorFacade.getWebstoreUrls(storeId), {
    ...options,
  });
};
