import styled from 'styled-components';
import type { IPlacePickerTemplate } from '../../themes';

export const MapContainer = styled.div<{ theme: IPlacePickerTemplate }>`
  height: calc(100vh - ${({ theme }) => theme.header.height});
  @media (max-width: 796px) {
    position: relative;
    height: 200px;
  }
`;

export const Banner = styled.div<{ theme: IPlacePickerTemplate }>`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.header.height});
  background-color: ${({ theme }) => theme.banner?.backgroundColor?.desktop};
  background-image: url(${({ theme }) => theme.banner?.imgUrl?.desktop});
  background-repeat: no-repeat;
  background-size: ${({ theme }) => theme.banner?.imgSize?.desktop ?? 'contain'};
  background-position: ${({ theme }) => theme.banner?.imgPosition?.desktop};
`;

export const BannerMobile = styled.div<{ theme: IPlacePickerTemplate }>`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.banner?.backgroundColor?.mobile};
  background-image: url(${({ theme }) => theme.banner?.imgUrl?.mobile});
  background-size: ${({ theme }) => theme.banner?.imgSize?.mobile ?? 'contain'};
  background-repeat: no-repeat;
  background-position: ${({ theme }) => theme.banner?.imgPosition?.mobile};
`;
