import 'reflect-metadata';
import React from 'react';
import { render } from 'react-dom';

import { datadogRumInitWrapper } from '../shared/utils/dd-rum';
import Root from './containers/Root';

import './styles.css';
import './fonts.css';

datadogRumInitWrapper();

render(<Root />, document.getElementById('root'));

declare global {
  interface Window {
    merchantId: string;
    googleApiKey: string;
  }
}
